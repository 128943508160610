import { Link, NavLink } from "react-router-dom";
import logo from "./../images/logo.webp";
import { Routes, Route } from "react-router-dom";
import AuthConsumer from "../hooks/useAuth";
import Overview from "../components/overview";
import Upload from "../components/upload";
import BorrowersTable from "../components/borrowersTable";
import { useState } from "react";
import { useTour } from "@reactour/tour";
import { useEffect } from "react";
import SettingsContainer from "../components/settingsContainer";
import Transactions from "../components/transactions";
import DisplayTransactions from "../components/displayTransactions";
import tawkTo from "../utils/tawkTo";
import { Helmet } from "react-helmet";
import Sidebar from "../components/sidebar";
import Nav from "../components/nav";

export default function Dashboard() {
  const { logout, user, token } = AuthConsumer();
  const [isActive, setIsActive] = useState(true);
  const [modalActive, setModalActive] = useState(false);
  const [collapse, setCollapse] = useState(true);

  useEffect(() => {
    const isOld = Boolean(localStorage.getItem("cbl-isold"));
    if (!isOld) {
      setModalActive(true);
    }
    localStorage.setItem("cbl-isold", "string");
  }, [user]);
  const { setIsOpen } = useTour();

  const initializeChatWidget = async (email) => {
    let userEmail = JSON.stringify({
      email: email,
    });
    let response = await fetch("/api/organizations/retrieve-tawk-secrets", {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: userEmail,
    });
    let data = await response.json();
    const { success, hash, tawkWidgetId, tawkPropertyId } = data;
    if (success === true) {
      tawkTo(
        tawkPropertyId,
        tawkWidgetId,
        user?.businessName,
        user?.email,
        hash
      );
    }
  };

  useEffect(() => {
    if (user?.email) {
      initializeChatWidget(user?.email);
    }
  }, [user.email]);

  return (
    <div className="dashboard">
      <Helmet>
        <title>Debt Recovery Portal | Dashboard</title>
      </Helmet>
      <div className={`modal ${modalActive ? "is-active" : ""}`}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              Welcome to Pebblescore for lenders
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                setModalActive(false);
              }}
            ></button>
          </header>
          <section className="modal-card-body">
            <h1 className="title ">Debt recovery redefined</h1>
            <p className="subtitle">
              Let’s get you all setup for easy debt recovery
            </p>
            <p>
              We are excited to have you onboard Pebblescore. Our goal is to
              help you recover the loans owed to you by debtors in the most cost
              efficient way.{" "}
            </p>
            <p>
              To ensure this, we would be walking you through simple steps to
              begin your debt recovery process.
            </p>
          </section>
          <footer className="modal-card-foot">
            <button
              className="button button-purple"
              onClick={() => {
                setModalActive(false);
                setIsOpen(true);
              }}
            >
              Begin walkthrough
            </button>
            <button
              className="button button-gold"
              onClick={() => {
                setModalActive(false);
              }}
            >
              Skip
            </button>
          </footer>
        </div>
      </div>
      <div className="container-fluid h-screen" style={{ overflowY: "hidden" }}>
        <div className="columns h-screen" style={{ overflowY: "hidden" }}>
          <div className="column is-one-fifth">
            <Sidebar />
          </div>
          <div className="column is-four-fifth is-fullheight">
            <div className="full-width-fixed">
              <Nav />
              <div>
                {[user.phoneNumber, user.acName, user.acNumber].some(
                  (r) => Boolean(r) !== true
                ) && (
                  <Link className="pop" to="/dashboard/settings/">
                    <a href="/dashboard/settings/#bank">
                      Add your bank account so we can remit your recovered
                      loans.
                    </a>
                  </Link>
                )}
              </div>
            </div>

            <Routes>
              <Route
                exact
                path="/debtors/:debtorsId"
                element={<Transactions />}
              />
              <Route exact path="/debtors" element={<BorrowersTable />} />
              <Route exact path="/settings" element={<SettingsContainer />} />
              <Route
                exact
                path="/transactions"
                element={<DisplayTransactions />}
              />
              <Route exact path="/upload" element={<Upload />} />
              <Route exact path="/" element={<Overview />} />
            </Routes>
            {/* </div> */}
          </div>
        </div>
      </div>
      <style jsx="true">
        {`
          .pop {
            background-color: rgb(var(--pbl-gold));
            color: rgb(var(--pbl-purple));
            padding: 0.5rem;
            padding-left: 2.5vw;
            display: block;
            text-align: center;
          }
          .pop:hover {
            text-decoration: underline;
          }
          .button-purple {
            background: rgb(var(--pbl-purple));
            border-color: rgb(var(--pbl-purple));
            color: rgb(var(--pbl-white));
          }
          .button-purple:hover {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            color: rgb(var(--pbl-white));
          }
          .button-gold {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
          }
          .bg-purple {
            background: rgb(var(--pbl-purple));
            border-color: rgb(var(--pbl-purple));
            height: 100%;
            overflow-y: hidden;
          }
          .h-screen {
            min-height: 155vh;
          }
          // a:hover {
          //   color: #000000;
          // }
          .menu-list a {
            color: #ffffff;
          }
          .menu-list a:hover {
            background-color: rgb(var(--pbl-white));
            color: #000000;
          }
          .has-text-white {
            color: #000000;
          }
          .arrow {
            margin-left: 10px;
            font-size: 25px;
            font-weight: bold;
          }
          .full-width-fixed {
            position: fixed;
            z-index: 1000;
            width: 80%;
            top: 0;
          }
          .fixed {
            position: fixed;
            z-index: 10000;
          }
          // body {
          //   overflow-y: auto;
          //   overflow-x:hidden;
          // }
        `}
      </style>
    </div>
  );
}
