import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import AuthConsumer from "../hooks/useAuth";

const Nav = () => {
  const { logout, user, token } = AuthConsumer();
  const [isActive, setIsActive] = useState(true);
  return (

      <nav className="navbar container">
        <div
          id="navMenubd-example"
          className={`navbar-menu ${isActive ? "is-active" : ""}`}
        >
          <div className="navbar-end">
            <div className="navbar-item">Welcome, {user?.businessName}</div>
            <div className="navbar-item has-dropdown is-hoverable mr-3">
              <a className="navbar-link">
                <div className="avatar-round">
                  {user?.image ? (
                    <img
                      src={user.image}
                      alt="avatar"
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <img
                      src={
                        "https://res.cloudinary.com/dgi7yxxpz/image/upload/v1674129861/user_1_vyu27s.png"
                      }
                      alt="avatar"
                      style={{
                        borderRadius: "50%",
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                      }}
                    />
                    // <p>
                    //   {user?.businessName &&
                    //     user.businessName[0].toUpperCase()}
                    // </p>
                  )}
                </div>
              </a>
              <div className="navbar-dropdown is-right">
                <NavLink className="navbar-item" to="/dashboard/settings">
                  Settings
                </NavLink>
                <a
                  className="navbar-item"
                  onClick={() => {
                    logout();
                  }}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">
        {`
          .full-width-fixed {
            // position: fixed;
            // z-index: 100000;
            // width: 100%;
          }
        `}
      </style>
      </nav>
     
    
  );
};

export default Nav;
