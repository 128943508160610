const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const tawkTo = (propertyId, widgetId, businessName, email, hash) => {
  if (!window) {
    throw new Error("DOM is unavailable");
  }
  window.Tawk_API = window.Tawk_API || {};

  window.Tawk_LoadStart = new Date();

  // pass attributes to tawk.to on widget load
  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttributes(
      {
        name: `${businessName}`,
        email: validateEmail(email) ? email : String(email),
        hash: hash,
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const tawk = document.getElementById("tawkId");
  if (tawk) {
    // Prevent TawkTo to create root script if it already exists
    return window.Tawk_API;
  }

  const script = document.createElement("script");

  script.id = "tawkId";
  script.async = true;
  script.src = `https://embed.tawk.to/${propertyId}/${widgetId}`;
  script.charset = "UTF-8";
  script.setAttribute("crossorigin", "*");

  const first_script_tag = document.getElementsByTagName("script")[0];
  if (!first_script_tag || !first_script_tag.parentNode) {
    throw new Error("DOM is unavailable");
  }

  first_script_tag.parentNode.insertBefore(script, first_script_tag);
};

export default tawkTo;
