import { useEffect, useRef, useMemo, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthConsumer from "../hooks/useAuth";
import { Grid, h } from "gridjs";
import { RowSelection } from "gridjs/plugins/selection";
import "gridjs/dist/theme/mermaid.css";
import fetcher from "../utils/fetcher";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Transactions from "./transactions";
import { Helmet } from "react-helmet";

export default function BorrowersTable() {
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [noLoans, setNoLoans] = useState(false);
  const navigate = useNavigate();
  const { token, user } = AuthConsumer();
  const wrapperRef = useRef();
  const [currentState, setCurrentState] = useState(null);
  const [fullData, setFullData] = useState();

  const grid = useMemo(
    () =>
      new Grid({
        search: {
          enabled: true,
        },
        sort: true,
        style: {
          td: {
            fontSize: "0.72rem",
            padding: "5px",
            textAlign: "center",
          },
          th: {
            fontSize: "0.72rem",
            textAlign: "center",
          },
        },

        columns: [
          {
            name: "⋮",
            id: "_id",
            hidden: true,
          },
          {
            id: "checkbox",
            name: h("input", {
              type: "checkbox",
              onClick: (e) => {
                const [first, ...others] =
                  document.querySelectorAll(`[type="checkbox"]`);
                others.forEach((c) => {
                  c.checked !== e.target.checked && c.click();
                });
              },
            }),
            sort: {
              enabled: false,
            },
            plugin: {
              component: RowSelection,

              props: {
                id: (row) => row.cell(0).data,
              },
            },
          },
          {
            name: "Name",
            id: "name",
          },
          {
            name: "Email",
            id: "email",
          },
          // {
          //   name: "Phone",
          //   id: "phone",
          // },
          {
            name: "Loan amount",
            hidden: true,
            id: "loanAmount",
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          {
            id: "amountExpected",
            name: "Amount owed",
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          {
            name: "Amount paid",
            id: "amountPaid",
            hidden: true,
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          {
            name: "Amount recovered",
            id: "amountRecovered",
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          {
            name: "Amount remitted",
            hidden: true,
            id: "amountRemitted",
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          {
            name: "Outstanding Balance",
            id: "outstandingBalance",
            formatter: (cell) =>
              `${new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(cell)}`,
          },
          // {
          //   name: "Due date",
          //   id: "dueDate",
          //   formatter: (cell) =>
          //     `${new Intl.DateTimeFormat("en-NG", {
          //       dateStyle: "medium",
          //     }).format(new Date(cell))}`,
          // },

          // {
          //   id: "disbursementDate",
          //   name: "Disbursement date",
          //   formatter: (cell) =>
          //     `${new Intl.DateTimeFormat("en-NG", {
          //       dateStyle: "medium",
          //     }).format(new Date(cell))}`,
          // },
          {
            name: "",
            id: "action",
            formatter: (cell, row) => {
              return h(
                "p",
                {
                  className: "cursor is-underlined",
                  onClick: () => {
                    const obj = data.find((f) => f._id == row.cells[0].data);

                    setCurrentState({
                      ...obj,
                      totalRecovered: row.cells[7].data,
                    });
                  },
                },
                "View more"
              );
            },
          },
          ,
        ],
        data: [],
      }),
    [navigate, data.length]
  );

  useEffect(() => {
    wrapperRef.current.innerHTML = "";
    grid.render(wrapperRef.current);
  }, [grid]);

  useEffect(() => {
    wrapperRef.current.innerHTML = "";
    grid
      .updateConfig({
        server: {
          url: "/api/organizations/borrowers/fetch",
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
          then: ({ loans }) => {
            console.log(loans);
            loans = loans.map((l) => {
              const remit = l?.transactions
                ?.filter(
                  (g) => g?.type === "remittance" && g?.status === "success"
                )
                ?.reduce((acc, curr) => acc + curr.amount, 0);
              const sum = l?.transactions
                ?.filter(
                  (g) => g?.type === "deposit" && g?.status === "success"
                )
                ?.reduce((acc, curr) => acc + curr.amount, 0);
              return {
                ...l,
                amountRecovered: sum,
                outstandingBalance: l.amountExpected - sum,
                amountRemitted: remit,
              };
            });
            if (loans.length < 1 && page === 1) setNoLoans(true);
            setData(loans);
            return loans;
          },
          total: (data) => {
            return data.loans.length;
          },
          params: (prevParams, newParams) => {
            return { ...prevParams, page: newParams.page };
          },
        },
        pagination: {
          enabled: true,
          limit: 15,
        },
      })
      .forceRender();
  }, [grid, page, token]);


  return (
    <div className="container p-4 mt">
      <Helmet>
        <title>Debt Recovery Portal | Debtors</title>
      </Helmet>
      {Boolean(currentState) && (
        <Transactions
          viewModal={Boolean(currentState)}
          setViewModal={() => setCurrentState(null)}
          state={{ ...currentState }}
        />
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {noLoans ? (
        <div className="box">
          <div className="center-box mt-5 mb-5">
            <p className="subtitle is-3">
              You do not have any debtors uploaded
            </p>
            <p className="subtitle is-6">
              To begin your debt recovery, click the button below
            </p>
            <Link to="/dashboard/upload">
              <div className="file">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" />
                  <span className="file-cta">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label">Upload Debtors</span>
                  </span>
                </label>
              </div>
            </Link>
          </div>
        </div>
      ) : (
        <>
          <div className="push-right">
            <NavLink to="/dashboard/upload" className="navbar-item">
              <div className="file">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" />
                  <span className="file-cta button-plain">
                    <span className="file-label">Upload Debtors</span>
                    <span className="file-icon" style={{ marginLeft: "10px" }}>
                      <i className="fas fa-upload"></i>
                    </span>
                  </span>
                </label>
              </div>
            </NavLink>
            <button
              className="button is-link"
              onClick={() => {
                if (
                  window.confirm(
                    `You are about to invite your debtors to PebbleScore. Continue?`
                  )
                ) {
                  const ids =
                    grid.config.plugin.get("checkbox").props.store.state;

                  fetcher(`/api/organizations/borrowers/invite`, {
                    method: "POST",
                    headers: {
                      authorization: `Bearer ${token}`,
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ ids: ids.rowIds }),
                  })
                    .then((d) => {
                      if (d.failure) throw new Error(d.message);
                      window.alert("Debtors successfully invited.");
                      window.location.reload();
                    })
                    .catch((err) => {
                      toast.dismiss();
                      toast.error(err.message, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    });
                }
              }}
            >
              <span>Invite debtors</span>
            </button>
          </div>

          <div className="transactions">
            <div ref={wrapperRef}></div>
            <div className="push-right">
              <p></p>
              {/* <div className="field has-addons">
                <p className="control ">
                  <button
                    className="button"
                    onClick={() => {
                      setPage((p) => Math.max(p - 1, 1));
                    }}
                  >
                    <span className="icon is-medium">
                      <i className="fa-solid fa-caret-left"></i>
                    </span>
                    <span>Previous</span>
                  </button>
                </p>
                <p className="control">
                  <button className="button">
                    {page} of {Math.ceil(Number(user.loansCount) / 30)}
                  </button>
                </p>
                <p className="control">
                  <button
                    className="button"
                    onClick={() => {
                      if (page * 30 < Number(user.loansCount)) {
                        setPage((p) => p + 1);
                      }
                    }}
                  >
                    <span>Next</span>
                    <span className="icon is-medium">
                      <i className="fa-solid fa-caret-right"></i>
                    </span>
                  </button>
                </p>
              </div> */}
            </div>
          </div>
        </>
      )}

      <style jsx="true">{`
        .cursor {
          cursor: pointer;
        }
        .push-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
        }
        th[data-column-id="email"] {
          min-width: 100px;
        }
        th[data-column-id="phone"] {
          min-width: 100px;
        }
        th[data-column-id="name"] {
          min-width: 100px;
        }
        th[data-column-id="loanAmount"] {
          min-width: 100px;
        }
        th[data-column-id="amountExpected"] {
          min-width: 150px;
        }
        th[data-column-id="disbursementDate"] {
          min-width: 150px;
        }
        th[data-column-id="amountRecovered"] {
          min-width: 180px;
        }
        th[data-column-id="outstandingBalance"] {
          min-width: 180px;
        }
        th[data-column-id="amountRemitted"] {
          min-width: 190px;
        }
        th[data-column-id="amountPaid"] {
          min-width: 100px;
        }
        th[data-column-id="dueDate"] {
          min-width: 100px;
        }
        th[data-column-id="action"] {
          min-width: 120px;
          font-weight: bold;
        }
        .gridjs-tr:last-of-type {
          margin-bottom: 20px !important;
        }
        #transactions {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
        }

        #transactions-header {
          display: flex;
          justify-content: space-between;
        }

        .transactions-div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 4px;
          margin: 0 auto;
          width: 100%;
        }

        .transactions-div span {
          margin-bottom: 1rem;
        }

        .transaction {
          display: flex;
          flex-direction: column;
        }

        #freelance-amount span {
          color: #00f5c3;
        }
        .button-plain{
          background-color: none !important;
        }
        span.file-cta:hover {
          background-color: rgb(var(--pbl-purple))
          border-color: rgb(var(--pbl-white));
          color: rgb(var(--pbl-black));
        }
        .mt {
          margin-top: 100px;
        }
      `}</style>
    </div>
  );
}
