import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthConsumer from "../hooks/useAuth";
import { Helmet } from "react-helmet";

export default function Upload() {
  const [loading, setLoading] = useState(false);

  const [fileName, setFileName] = useState(
    "Files accepted: .xls, .xlsx and .csv"
  );
  const {
    register,
    handleSubmit,

    getValues,
  } = useForm();
  const formRef = useRef();
  const { token } = AuthConsumer();

  const onSubmit = async () => {
    try {
      toast.dismiss();
      if (getValues("file")[0]?.size > 1048576 * 2)
        throw new Error("File size cannot exceed 2mb");
      setLoading(true);
      const id = toast.loading("Please wait...");

      const formData = new FormData(formRef.current);

      const res = await fetch(`/api/organizations/borrowers/upload`, {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: formData,
      });
      if (!res.ok || !res.status.toString().startsWith("2")) {
        throw await res.json();
      }
      await res.json();
      setLoading(false);
      toast.update(id, {
        render: "Upload successful",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.replace("/dashboard/debtors");
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container p-4">
      <Helmet>
        <title>Debt Recovery Portal | Upload Debtors</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <h4 className="title is-size-3 text-purple mb-3">Upload</h4>
      <h5 className="subtitle is-6 mt-4">Upload your debtors list</h5>
      <div className="right">
        <a
          className="button is-link mt-3 mb-3"
          href="https://res.cloudinary.com/dgi7yxxpz/raw/upload/v1674143826/pebblescore-lenders-template_qwzhp4_m8t456.xlsx"
          target="_blank"
          rel="noreferrer"
        >
          Download template
          <span className="ml-2">
            <i className="fa-solid fa-download"></i>
          </span>
        </a>
      </div>
      {loading ? (
        <div className="spin-container blob">
          <div
            className="spinner spinner-rotate border-spinner-green"
            data-mx-time="2.2"
          ></div>
          <p className="modal-card-title has-text-centered mt-5 mb-5">
            Uploading debtors...
          </p>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} action="#" ref={formRef}>
          <div className="blob">
            <div className="file is-large is-boxed has-name is-centered">
              <label className="file-label">
                <input
                  className="file-input"
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  {...register("file", {
                    required: true,
                    onChange: () => {
                      if (getValues("file")[0]?.name) {
                        setFileName(getValues("file")[0]?.name);
                        onSubmit();
                      }
                    },
                  })}
                />
                <span className="file-cta uploader">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">
                    Click or drag here to upload debtors list
                  </span>
                </span>
                <span
                  className="file-name has-text-centered"
                  style={{ minWidth: "100%" }}
                >
                  {fileName}
                </span>
              </label>
            </div>
          </div>
        </form>
      )}

      <style jsx="true">
        {`
          .spin-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .text-purple {
            color: rgb(var(--pbl-purple));
          }
          .blob {
            margin-top: 5rem;
            margin-bottom: 5rem;
          }
          span.uploader {
            padding-top: 6rem !important;
            padding-bottom: 6rem !important;
          }
          .spinner {
            padding: 1rem;
            transform: scale(1);
            background: #000;
            opacity: 1;
            border-radius: 100%;
            animation: spinner ease-in-out 1s infinite;
            height: 100px;
            width: 100px;
          }
          .spinner-rotate {
            padding: 1rem;
            border-top: 10px solid #000;
            border-left: 10px solid #000;
            border-right: 10px solid #000;
            border-bottom: 10px solid transparent;
            border-radius: 50%;
            background: none;
            animation: spinner-rotate linear 1s infinite;
            margin: 5px;
          }
          .border-spinner-green {
            border-top-color: #ccc !important;
            border-left-color: #ccc !important;
            border-right-color: #ccc !important;
          }
          /* animations */
          @keyframes spinner {
            from {
              transform: scale(0);
              opacity: 1;
            }
            to {
              transform: scale(1);
              opacity: 0;
            }
          }
          @keyframes spinner-rotate {
            from {
              transform: rotateZ(0);
            }
            to {
              transform: rotateZ(1turn);
            }
          }
          .right {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
          }
        `}
      </style>
    </div>
  );
}
