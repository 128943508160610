import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
const usePasswordToggle = () => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);

  // if(visiblePassword === true){
  //   setVisiblePassword(false);
  // }
  // else {
  //   setVisiblePassword(true);
  // }

  const iconPassword = (
    <FontAwesomeIcon
      icon={visiblePassword ? faEyeSlash : faEye}
      onClick={() => setVisiblePassword((prev) => !prev)}
    />
  );

  const iconConfirm = (
    <FontAwesomeIcon
      icon={visibleConfirm ? faEyeSlash : faEye}
      onClick={() => setVisibleConfirm((prev) => !prev)}
    />
  );

  return [visiblePassword, visibleConfirm, iconPassword, iconConfirm];

};

export default usePasswordToggle;
