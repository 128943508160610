import { useForm } from "react-hook-form";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthConsumer from "../hooks/useAuth";
import fetcher from "../utils/fetcher";

export default function PasswordSettings() {
  const [loading, setLoading] = useState(false);
  const { token } = AuthConsumer();
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    },
  });
  const onSubmit = async (data) => {
    try {
      if (data.newPassword !== data.confirmPassword) {
        throw new Error("Please confirm password");
      }
      const id = toast.loading("Please wait...");
      setLoading(true);

      const { failure, message } = await fetcher(
        `/api/organizations/update/password`,
        {
          method: "PUT",
          body: JSON.stringify({ ...data }),
          headers: {
            authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (failure) throw new Error(message);
      setLoading(false);
      toast.dismiss();

      toast.update(id, {
        render: "Password successfully reset",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload();
    } catch (err) {
      setLoading(false);
      console.log(err);
      toast.dismiss();
      toast.error(err.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container ">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="box mt-2 mb-5">
          <h1 className="title is-2 text-primary">Password Details</h1>
          <div className="pack">
            <div className="half-pack">
              <div className="field">
                <label className="label">Old password</label>
                <p className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${errors.oldPassword ? "is-danger" : ""}`}
                    type="password"
                    placeholder="Old password"
                    {...register("oldPassword", { required: true })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-eye"></i>
                  </span>
                  {errors.oldPassword && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </p>
                {errors.oldPassword && (
                  <p className="help is-danger">Please provide old password</p>
                )}
              </div>
              <div className="field">
                <label className="label">New password</label>
                <p className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${errors.newPassword ? "is-danger" : ""}`}
                    type="password"
                    placeholder="New password"
                    {...register("newPassword", { required: true })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-eye"></i>
                  </span>
                  {errors.newPassword && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </p>
                {errors.newPassword && (
                  <p className="help is-danger">Please provide new password</p>
                )}
              </div>
              <div className="field">
                <label className="label">Confirm new password</label>
                <p className="control has-icons-left has-icons-right">
                  <input
                    className={`input ${
                      errors.confirmPassword ? "is-danger" : ""
                    }`}
                    type="password"
                    placeholder="Confirm password"
                    {...register("confirmPassword", { required: true })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-eye"></i>
                  </span>
                  {errors.confirmPassword && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </p>
                {errors.confirmPassword && (
                  <p className="help is-danger">Please verify your password</p>
                )}
              </div>
            </div>

            <div className="half-pack"></div>
          </div>
          <div className="field mt-5 mb-4">
            <p className="control">
              <button
                className={`button button-gold is-medium ${
                  loading ? "is-loading " : ""
                }`}
              >
                Change password
              </button>
            </p>
          </div>
        </div>
      </form>
      <style jsx="true">
        {`
          .pack {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            grid-gap: 3rem;
            justify-content: space-between;
          }
          .button-gold {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            color: rgb(var(--pbl-black));
          }
        `}
      </style>
    </div>
  );
}
