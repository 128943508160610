import { useState, useEffect } from "react";
import AuthConsumer from "../hooks/useAuth";
import fetcher from "../utils/fetcher";
import { Helmet } from "react-helmet";

export default function DisplayTransactions() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const { token } = AuthConsumer();

  useEffect(() => {
    setLoading(true);
    fetcher(`/api/organizations/account/transactions/full?page=${page}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    })
      .then(({ transactions }) => {
        setData(transactions.filter((t) => t.status === "success"));
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [page, setData]);
  return (
    <div className="container p-5">
      <Helmet>
        <title>Debt Recovery Portal | Transactions</title>
      </Helmet>
      <h2 className="title is-size-3 text-purple mt">Transactions</h2>
      {loading && (
        <div className="spin-container blob">
          <div
            className="spinner spinner-rotate border-spinner-green"
            data-mx-time="2.2"
          ></div>
        </div>
      )}
      {data.length <= 0 && !loading && (
        <div className="center-box mt-5 mb-5">
          <p className="subtitle is-3">
            You do not have any transactions for this period
          </p>
        </div>
      )}

      {!loading && (
        <>
          <div className="house">
            <div className="trans-card">
              <div>
                <h5 className="has-text-weight-bold">Amount</h5>
              </div>
              <div>
                <p className="has-text-weight-bold">Description</p>
              </div>

              <div>
                <p className="has-text-weight-bold">Status</p>
              </div>
              <div>
                <p className="has-text-weight-bold">Date</p>
              </div>
            </div>
            {data.map((d) => (
              <div className="trans-card" key={d._id}>
                <div className="bold">
                  <h5 className="has-text-weight-bold">
                    <span className="bold-icon">
                      {d.type == "deposit" ? (
                        <i className="fa-solid fa-arrow-right has-text-primary"></i>
                      ) : (
                        <i className="fa-solid fa-arrow-left has-text-danger"></i>
                      )}
                    </span>
                    {new Intl.NumberFormat("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }).format(d.amount)}
                  </h5>
                </div>
                <div>
                  <p>
                    {d.type === "deposit" &&
                      `Repayment from ${d.authors[0].firstName} ${d.authors[0].lastName}`}
                    {d.type === "deduction" && `Cobble fee`}
                  </p>
                </div>

                <div>
                  <p style={{ textTransform: "capitalize" }}>{d.status}</p>
                </div>
                <div>
                  <p>
                    {new Intl.DateTimeFormat("en-NG", {
                      dateStyle: "long",
                    }).format(new Date(d.createdAt))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="push-right">
        <p></p>
        <div className="field has-addons">
          <p className="control ">
            <button
              className="button"
              onClick={() => {
                setPage((p) => Math.max(p - 1, 1));
              }}
            >
              <span className="icon is-medium">
                <i className="fa-solid fa-caret-left"></i>
              </span>
              <span>Previous</span>
            </button>
          </p>
          <p className="control">
            <button className="button">{page}</button>
          </p>
          <p className="control">
            <button
              className="button"
              onClick={() => {
                if (data.length >= 30) {
                  setPage((p) => p + 1);
                }
              }}
            >
              <span>Next</span>
              <span className="icon is-medium">
                <i className="fa-solid fa-caret-right"></i>
              </span>
            </button>
          </p>
        </div>
      </div>
      <style jsx="true">{`
        .bold-icon {
          margin-right: 0.5rem;
        }
        .grid-tr {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
          grid-gap: 1rem;
        }

        .house {
          overflow-x: scroll;
          padding-bottom: 1rem;
        }
        .trans-card > * {
          flex-basis: 300px;
          flex-grow: 1;
          margin-right: 1rem;
          font-size: 0.9rem;
        }
        .trans-card {
          display: flex;
          min-width: 800px;
          justify-content: center;
          align-items: center;
          margin-bottom: 1rem;
          margin-top: 1rem;
        }
        .select {
          margin-top: 1rem;
          margin-bottom: 2rem;
        }
        .push-right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
        }
        .spin-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .blob {
          margin-top: 5rem;
          margin-bottom: 5rem;
        }
        span.uploader {
          padding-top: 6rem !important;
          padding-bottom: 6rem !important;
        }
        .spinner {
          padding: 1rem;
          transform: scale(1);
          background: #000;
          opacity: 1;
          border-radius: 100%;
          animation: spinner ease-in-out 1s infinite;
          height: 100px;
          width: 100px;
        }
        .spinner-rotate {
          padding: 1rem;
          border-top: 10px solid #000;
          border-left: 10px solid #000;
          border-right: 10px solid #000;
          border-bottom: 10px solid transparent;
          border-radius: 50%;
          background: none;
          animation: spinner-rotate linear 1s infinite;
          margin: 5px;
        }
        .border-spinner-green {
          border-top-color: #ccc !important;
          border-left-color: #ccc !important;
          border-right-color: #ccc !important;
        }
        /* animations */
        @keyframes spinner {
          from {
            transform: scale(0);
            opacity: 1;
          }
          to {
            transform: scale(1);
            opacity: 0;
          }
        }
        @keyframes spinner-rotate {
          from {
            transform: rotateZ(0);
          }
          to {
            transform: rotateZ(1turn);
          }
        }
        .mt{
          margin-top: 100px;
        }
        .text-purple {
          color: rgb(var(--pbl-purple));
        }
      `}</style>
    </div>
  );
}
