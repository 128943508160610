import { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";

import AuthConsumer from "../hooks/useAuth";
import fetcher from "../utils/fetcher";
function* populateYears() {
  const year = new Date().getFullYear();
  for (let i = 0; i <= 100; i++) {
    yield year - i;
  }
}
function batchIntoYear(year, transactions) {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return months.map((m) => {
    const monthString = new Intl.DateTimeFormat("en-NG", {
      month: "short",
    }).format(new Date(`${year}-${m}`));

    return {
      month: monthString,
      amount: transactions
        .filter(
          (t) =>
            new Intl.DateTimeFormat("en-NG", {
              month: "short",
              year: "numeric",
            }).format(new Date(t.createdAt)) ===
            new Intl.DateTimeFormat("en-NG", {
              month: "short",
              year: "numeric",
            }).format(new Date(`${year}-${m}`))
        )
        .reduce((acc, curr) => acc + curr.amount, 0),
    };
  });
}
export default function Overview() {
  const { user, token } = AuthConsumer();
  const [years] = useState([...populateYears()]);
  const [displayList, setDisplayList] = useState([]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(true);
  const barRef = useRef();

  useEffect(() => {
    setLoading(true);
    fetcher(`/api/organizations/account/transactions?year=${currentYear}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(({ transactions }) => {
        setDisplayList(transactions);
        setLoading(false);
      })
      .catch(console.log);
  }, [currentYear, token, setLoading]);
  useEffect(() => {
    if (loading || displayList.length < 1) return;

    const batch = batchIntoYear(currentYear, displayList);
    const chart = new window.Chart(barRef.current, {
      type: "bar",
      responsive: true,
      data: {
        labels: batch.map((b) => b.month),
        datasets: [
          {
            data: batch.map((b) => b.amount),
            hoverBackgroundColor: ["rgba(0 ,209, 178,.9)"],
          },
        ],
      },

      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            xAlign: "center",
            yAlign: "bottom",
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";

                if (label) {
                  label += ": ";
                }
                if (context.parsed.y !== null) {
                  label += new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(context.parsed.y);
                }
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            display: true,

            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              font: {
                color: "#ddd",
              },
            },
          },
          y: {
            min: 0,
            max: Math.max(Math.max(...batch.map((b) => b.amount)), 500),

            grid: {
              drawBorder: false,
            },
            ticks: {
              stepSize: Math.max(
                Math.max(...batch.map((b) => b.amount)) * 0.2,
                100
              ),
              font: {
                color: "#ddd",
              },
            },
          },
        },
      },
    });
    return () => {
      chart.destroy();
    };
  }, [currentYear, displayList.length, loading]);

  return (
    <div
      className="container p-4"
      style={{ height: "inherit" }}
    >
      <div
        style={{
          flexWrap: "wrap",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <h4 className="title is-size-3 text-purple mt">Dashboard</h4>
        <NavLink to="/dashboard/upload" className="navbar-item" reloadDocument>
          <div className="file is-link">
            <label className="file-label">
              <span className="file-input" type="file" name="resume"></span>
              <span className="file-cta">
                <span className="file-label ">Upload Debtors</span>
                <span className="file-icon" style={{ marginLeft: "10px" }}>
                  <i className="fas fa-upload"></i>
                </span>
              </span>
            </label>
          </div>
        </NavLink>
      </div>
      <div className="columns mt-3">
        <div className="column">
          <div className="box has-text-centered" data-name="debtors">
            <p className="title is-4">
              {new Intl.NumberFormat("en-NG", {}).format(user.loansCount)}
            </p>
            <p className="subtitle is-6">Debtors</p>
          </div>
        </div>
        <div className="column">
          <div className="box has-text-centered" data-name="bad-loans">
            <p className="title is-4">
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(user.totalOwed)}
            </p>
            <p className="subtitle is-6">Bad Loans</p>
          </div>
        </div>
        <div className="column">
          <div className="box has-text-centered" data-name="recovered">
            <p className="title is-4">{user.plansCount}</p>
            <p className="subtitle is-6"> Plans Created</p>
          </div>
        </div>
      </div>
      <div className="columns mt-3">
        <div className="column">
          <div className="box has-text-centered" data-name="recovered">
            <p className="title is-4">
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(user.plansAmount || 0)}
            </p>
            <p className="subtitle is-6">Value of Plans Created</p>
          </div>
        </div>
        <div className="column">
          <div className="box has-text-centered" data-name="recovered">
            <p className="title is-4">
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(user.totalRecovered)}
            </p>
            <p className="subtitle is-6">Recovered</p>
          </div>
        </div>
        <div className="column">
          <div className="box has-text-centered" data-name="recovered">
            <p className="title is-4">
              {new Intl.NumberFormat("en-NG", {
                style: "currency",
                currency: "NGN",
              }).format(user.totalRemitted || 0)}
            </p>
            <p className="subtitle is-6">Remitted</p>
          </div>
        </div>
      </div>
      <div className="right">
        <h2 className="has-text-weight-bold mr-3">Amount recovered</h2>
        <div className="select">
          <select
            name="years"
            id="years"
            onChange={({ target: { value } }) => setCurrentYear(value)}
          >
            {years.map((y, index) =>
              index === years.length - 1 ? (
                <option value={y} key={y} defaultValue>
                  {y}
                </option>
              ) : (
                <option value={y} key={y}>
                  {y}
                </option>
              )
            )}
          </select>
        </div>
      </div>
      {loading && (
        <div className="spin-container blob">
          <div
            className="spinner spinner-rotate border-spinner-green"
            data-mx-time="2.2"
          ></div>
        </div>
      )}
      {user?.totalOwed <= 0 && !loading && (
        <div className="box">
          <div className="center-box mt-5 mb-5">
            <p className="subtitle is-3">
              You do not have any debtors uploaded
            </p>
            <p className="subtitle is-6">
              To begin your debt recovery, click the button below
            </p>
            <Link to="/dashboard/upload">
              <div className="file is-link">
                <label className="file-label">
                  <input className="file-input" type="file" name="resume" />
                  <span className="file-cta is-link">
                    <span className="file-icon">
                      <i className="fas fa-upload"></i>
                    </span>
                    <span className="file-label text-primary">
                      Upload Debtors
                    </span>
                  </span>
                </label>
              </div>
            </Link>
          </div>
        </div>
      )}

      {displayList.length <= 0 && !loading && user?.totalOwed > 0 && (
        <div className="center-box mt-5 mb-5">
          <p className="subtitle is-3">
            You do not have any recovered amount data for this period
          </p>
        </div>
      )}
      <div className="graph">
        <canvas ref={barRef} id="graph"></canvas>
      </div>
      <style jsx="true">
        {`
          .button-purple {
            background-color: rgb(var(--pbl-purple));
            border-color: rgb(var(--pbl-purple));
            color: rgb(var(--pbl-white));
          }
          .button-purple:hover {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            color: var(--pbl-black);
          }
          .select {
            margin-top: 1rem;
            margin-bottom: 2rem;
          }
          .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
          }
          #graph {
            max-height: 300px;
          }
          .spin-container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          }
          .blob {
            margin-top: 5rem;
            margin-bottom: 5rem;
          }
          span.uploader {
            padding-top: 6rem !important;
            padding-bottom: 6rem !important;
          }
          .spinner {
            padding: 1rem;
            transform: scale(1);
            background: #000;
            opacity: 1;
            border-radius: 100%;
            animation: spinner ease-in-out 1s infinite;
            height: 100px;
            width: 100px;
          }
          .spinner-rotate {
            padding: 1rem;
            border-top: 10px solid #000;
            border-left: 10px solid #000;
            border-right: 10px solid #000;
            border-bottom: 10px solid transparent;
            border-radius: 50%;
            background: none;
            animation: spinner-rotate linear 1s infinite;
            margin: 5px;
          }
          .border-spinner-green {
            border-top-color: #ccc !important;
            border-left-color: #ccc !important;
            border-right-color: #ccc !important;
          }
          /* animations */
          @keyframes spinner {
            from {
              transform: scale(0);
              opacity: 1;
            }
            to {
              transform: scale(1);
              opacity: 0;
            }
          }
          @keyframes spinner-rotate {
            from {
              transform: rotateZ(0);
            }
            to {
              transform: rotateZ(1turn);
            }
          }

          .colored {
            background: rgb(var(--pbl-purple));
          }
          .text-gold {
            color: rgb(var(--pbl-gold));
          }
          .text-purple {
            color: rgb(var(--pbl-purple));
          }
          .bg-gold {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            height: 100%;
            overflow-y: hidden;
          }
          // .button-purple {
          //   background: rgb(var(--pbl-purple));
          //   border-color: rgb(var(--pbl-purple));
          //   color: rgb(var(--pbl-white));
          // }
          // .button-purple:hover {
          //   background: rgb(var(--pbl-gold));
          //   border-color: rgb(var(--pbl-gold)); 
          //   color: var(--pbl-black);
          // }
          .mt {
            margin-top: 100px;
          }
          .overflow-hidden {
            overflow: hidden;
          }
          .columns {
            height: 100%;
          }
        `}
      </style>
    </div>
  );
}
