import * as React from "react";
import fetcher from "../utils/fetcher";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const authContext = React.createContext();

function useAuth() {
  let navigate = useNavigate();
  const [token, setToken] = React.useState(Cookies.get("cbl-lenders-auth"));
  const [authed, setAuthed] = React.useState(Boolean(token));
  const [user, setUser] = React.useState({
    _id: "",
    businessName: "",
    slug: "",
    email: "",
    isActive: false,
    country: "",
    createdAt: "",
    updatedAt: "",
    loans: [],
    loansCount: 0,
    totalOwed: 0,
    totalRecovered: 0,
    totalGiven: 0,
    acNumber: "",
    bank: "",
    acName: "",
    bankName: "",
    plansCount: 0,
    plansAmount: 0,
  });
  React.useEffect(() => {
    if (token) {
      fetcher("/api/organizations/me", {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((body) => {
        if (body.failure) {
          Cookies.remove("cbl-lenders-auth");
          setToken(undefined);
          setAuthed(false);
          navigate("/login", { replace: true });
        }
        setUser((s) => ({ ...s, ...body.organization }));
      });
    }
    return () => {
      setUser({
        _id: "",
        businessName: "",
        slug: "",
        email: "",
        isActive: false,
        country: "",
        createdAt: "",
        updatedAt: "",
        loans: [],
        loansCount: 0,
        totalOwed: 0,
        totalRecovered: 0,
        totalGiven: 0,
        acNumber: "",
        bank: "",
        acName: "",
        bankName: "",
        plansCount: 0,
        plansAmount: 0,
      });
    };
  }, [setUser, token, navigate]);

  return {
    user,
    setUser,
    token,
    authed,
    async login(state = {}) {
      const body = await fetcher("/api/organizations/login", {
        method: "POST",
        body: JSON.stringify(state),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (body.failure) {
        throw body.message;
      }
      setToken(body.token);
      setAuthed(true);
      Cookies.set("cbl-lenders-auth", body.token, { expires: 1 });
      setAuthed(true);
      return body;
    },
    async signup(state = {}) {
      const body = await fetcher("/api/organizations/signup", {
        method: "POST",
        body: JSON.stringify({
          ...state,
          origin: new URL("/verifyAdmin", window.location.origin),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (body.failure) {
        throw body.message;
      }

      return body;
    },
    logout() {
      Cookies.remove("cbl-lenders-auth");
      setToken(undefined);
      setAuthed(false);
      navigate("/login", { replace: true });
    },
  };
}

export function AuthProvider({ children }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
