import { Link } from "react-router-dom";
import logo from "./../images/logo.webp";
import { useForm } from "react-hook-form";
import { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import fetcher from "../utils/fetcher";
import { useNavigate, NavLink } from "react-router-dom";

export default function SubmitToken() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [passwordResetStep, setPasswordResetStep] = useState(1);
  const [resetToken, setResetToken] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      tempToken: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (data.password !== data.confirmPassword)
        throw new Error("Please confirm password");

      setLoading(true);

      const token = new URLSearchParams(window.location.search).get("token");
      const { failure, message, success } = await fetcher(
        `/api/organizations/update/password/new`,
        {
          method: "PUT",
          body: JSON.stringify({
            ...data,
            organizationId: token,
            tempToken: resetToken,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (failure) throw new Error(message);
      setLoading(false);
      toast.success("Password reset successful", {
        render: "Password reset successful",
        type: "success",
        isLoading: false,
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (success) {
        setTimeout(() => {
          navigate("/login");
        }, 1000);
      }
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container-fluid stretch">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="hero colored is-fullheight full-height">
        <div className="hero-head space-remove">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <NavLink to="/" className="navbar-item">
                  <img
                    src="https://res.cloudinary.com/dgi7yxxpz/image/upload/v1673884574/PebbleSccore_with_WhiteTXTName_ugq2l6.png"
                    alt="Logo"
                    width={"150%"}
                  />
                </NavLink>
                <span className="navbar-burger" data-target="navbarMenuHeroA">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenuHeroA" className="navbar-menu">
                <div className="navbar-end">
                  <NavLink to="/" className="navbar-item">
                    Home
                  </NavLink>
                  {/* <NavLink to="/" className="navbar-item">What's New?</NavLink> */}
                  <span className="navbar-item ">
                    <NavLink to="/login" className="button bg-gold is-medium">
                      <span>Login</span>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body">
          <div className="container">
            <div className="columns  is-8-desktop is-align-items-center">
              <div className="column  is-half-tablet is-half-desktop is-hidden-mobile">
                <div className="hug is-max-desktop">
                  {/* <h3 className="subtitle is-size-3 has-text-light precheif">
                  Cobble for lenders
                </h3> */}

                  {passwordResetStep === 1 ? (
                    <div>
                      <h1 className="chief has-text-white">Reset Token</h1>
                      <h4 className="is-size-4 has-text-light">
                        Enter password reset token.
                      </h4>
                    </div>
                  ) : (
                    <div>
                      <h1 className="chief has-text-white">Reset Password</h1>
                      <h4 className="is-size-4 has-text-light">
                        Fill in your preferred new password.
                      </h4>
                    </div>
                  )}
                </div>
              </div>
              <div className="column  is-half-tablet is-half-desktop">
                {passwordResetStep === 1 ? (
                  <form className="form">
                    <div className="">
                      <h1 className="title mb-5">Enter token</h1>
                    </div>
                    <div>
                      {passwordResetStep === 1 ? (
                        <div className="field">
                          <label className="label">Password reset token</label>
                          <p className="control has-icons-left has-icons-right">
                            <input
                              onChange={(e) => setResetToken(e.target.value)}
                              value={resetToken}
                              className={`input ${
                                /^\d{6}$/.test(Number(resetToken.length))
                                  ? "is-danger"
                                  : ""
                              }`}
                              type="number"
                              placeholder="Password reset token"
                              // {...register("tempToken", { required: true })}
                            />
                            <span className="icon is-small is-left">
                              <i className="fa-solid fa-key"></i>
                            </span>
                            {/^\d{6}$/.test(Number(resetToken.length)) && (
                              <span className="icon is-small is-right">
                                <i className="fas fa-exclamation-triangle"></i>
                              </span>
                            )}
                          </p>
                          {/^\d{6}$/.test(resetToken.length) && (
                            <p className="help is-danger">
                              Please enter password reset token
                            </p>
                          )}
                          <div className="field mt-5 mb-4">
                            <p className="control">
                              {resetToken.length !== 6 ? (
                                <button
                                  disabled={resetToken.length !== 6}
                                  onClick={() => {
                                    setPasswordResetStep(2);
                                  }}
                                  className={`button button-purple has-text-black is-medium ${
                                    loading ? "is-loading " : ""
                                  }`}
                                >
                                  {passwordResetStep === 1
                                    ? "Submit token"
                                    : passwordResetStep === 2
                                    ? "Reset password"
                                    : null}
                                </button>
                              ) : (
                                <button
                                  onClick={() => {
                                    setPasswordResetStep(2);
                                  }}
                                  className={`button button-purple has-text-white is-medium ${
                                    loading ? "is-loading " : ""
                                  }`}
                                >
                                  Submit token
                                </button>
                              )}
                            </p>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </form>
                ) : passwordResetStep === 2 ? (
                  <form onSubmit={handleSubmit(onSubmit)} className="form">
                    <div className="">
                      <h1 className="title mb-5">Enter new password</h1>
                    </div>
                    <div className="field">
                      <label className="label"> New password</label>
                      <p className="control has-icons-left has-icons-right">
                        <input
                          className={`input ${
                            errors.password ? "is-danger" : ""
                          }`}
                          type="password"
                          placeholder="Password"
                          {...register("password", { required: true })}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-eye"></i>
                        </span>
                        {errors.password && (
                          <span className="icon is-small is-right">
                            <i className="fas fa-exclamation-triangle"></i>
                          </span>
                        )}
                      </p>
                      {errors.password && (
                        <p className="help is-danger">
                          Please provide password
                        </p>
                      )}
                    </div>
                    <div className="field">
                      <label className="label">Confirm new password</label>
                      <p className="control has-icons-left has-icons-right">
                        <input
                          className={`input ${
                            errors.confirmPassword ? "is-danger" : ""
                          }`}
                          type="password"
                          placeholder="Confirm password"
                          {...register("confirmPassword", { required: true })}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-eye"></i>
                        </span>
                        {errors.confirmPassword && (
                          <span className="icon is-small is-right">
                            <i className="fas fa-exclamation-triangle"></i>
                          </span>
                        )}
                      </p>
                      {errors.confirmPassword && (
                        <p className="help is-danger">
                          Please verify your password
                        </p>
                      )}
                    </div>
                    <div className="field mt-5 mb-4">
                      <p className="control">
                        <button
                          onClick={() => {
                            setPasswordResetStep(2);
                          }}
                          className={`button button-purple has-text-white is-medium ${
                            loading ? "is-loading " : ""
                          }`}
                        >
                          Reset password
                        </button>
                      </p>
                    </div>
                  </form>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx={"true"}>{`
        .extend {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin-top: 11rem;
        }
        .bg-gold {
          background: rgb(var(--pbl-gold));
          border-color: rgb(var(--pbl-gold));
          height: 100%;
          overflow-y: hidden;
        }
        .button-purple {
          background: rgb(var(--pbl-purple));
          border-color: rgb(var(--pbl-purple));
          color: rgb(var(--pbl-white));
        }
        .form {
          background-color: rgb(var(--pbl-white));
          padding: 1.5rem 2.5rem;
          border-radius: 10px;
        }
        .navbar-item {
          color: #ffffff;
        }
        .colored {
          background: rgb(var(--pbl-purple));
        }
        .text-gold {
          color: rgb(var(--pbl-gold));
        }
        .colored {
          background: rgb(var(--pbl-purple));
        }
        .stretch {
          min-height: 100vh;
        }
        .hug {
          padding: 1rem;
        }
        .chief {
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
          margin-top: 4rem;
        }
        .precheif {
          margin-top: 11rem;
        }
        .content h1 {
          margin-top: 3rem;
        }
      `}</style>
    </div>
  );
}
