import { useEffect, useRef, useState } from "react";
import { Helmet } from 'react-helmet';

function* populateYears() {
  const year = new Date().getFullYear();
  for (let i = 0; i <= 100; i++) {
    yield year - i;
  }
}
function batchIntoYear(year, transactions) {
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  return months.map((m) => {
    const monthString = new Intl.DateTimeFormat("en-NG", {
      month: "short",
    }).format(new Date(`${year}-${m}`));

    return {
      month: monthString,
      amount: transactions
        .filter(
          (t) =>
            new Intl.DateTimeFormat("en-NG", {
              month: "short",
              year: "numeric",
            }).format(new Date(t.createdAt)) ===
            new Intl.DateTimeFormat("en-NG", {
              month: "short",
              year: "numeric",
            }).format(new Date(`${year}-${m}`))
        )
        .reduce((acc, curr) => acc + curr.amount, 0),
    };
  });
}
export default function Transactions(props) {
  const { viewModal, setViewModal, state } = props;
  const [displayList, setDisplayList] = useState([]);
  const [years] = useState([...populateYears()]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const barRef = useRef();

  useEffect(() => {
    if (state?.totalRecovered) {
      const filteredByYear = state.transactions
        .filter((f) => f.status === "success" && f.type === "deposit")
        .filter(
          (t) => new Date(t.createdAt).getFullYear() === Number(currentYear)
        );
      const batch = batchIntoYear(
        currentYear,
        state.transactions.filter(
          (f) => f.status === "success" && f.type === "deposit"
        )
      );
      setDisplayList(filteredByYear);
      const chart = new window.Chart(barRef.current, {
        type: "bar",
        responsive: true,
        data: {
          labels: batch.map((b) => b.month),
          datasets: [
            {
              data: batch.map((b) => b.amount),
              hoverBackgroundColor: ["rgba(0 ,209, 178,.9)"],
            },
          ],
        },

        options: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              xAlign: "center",
              yAlign: "bottom",
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || "";

                  if (label) {
                    label += ": ";
                  }
                  if (context.parsed.y !== null) {
                    label += new Intl.NumberFormat("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }).format(context.parsed.y);
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              display: true,

              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                font: {
                  color: "#ddd",
                },
              },
            },
            y: {
              min: 0,
              max: Math.max(Math.max(...batch.map((b) => b.amount)), 500),

              grid: {
                drawBorder: false,
              },
              ticks: {
                stepSize: Math.max(
                  Math.max(...batch.map((b) => b.amount)) * 0.2,
                  100
                ),
                font: {
                  color: "#ddd",
                },
              },
            },
          },
        },
      });
      return () => {
        chart.destroy();
      };
    }
  }, [currentYear, state?.transactions, state?.totalRecovered]);

  return (
    <div id="modal-js-example" className={`modal ${viewModal && "is-active"}`}>
      <div className="modal-background"></div>
      <div className="modal-content has-background-white">
        <div className="transactions">
          <div className="container" style={{ padding: "1rem" }}>
            <a href="#" className="mb-3" onClick={() => setViewModal(false)}>
              <span className="mr-1">
                <i className="fa-solid fa-arrow-left-long"></i>
              </span>{" "}
              Back
            </a>
            <div className="grid-tr mt-2">
              <h1>Name: {state.name}</h1>
              <h2>Loan ID: {state.loanId}</h2>
              <h2>
                Total amount recovered:{" "}
                <span className="has-text-weight-bold">
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(state.totalRecovered)}
                </span>
              </h2>
              <h2>Phone: {state.phone}</h2>
              <h2>
                Amount owed:{" "}
                <span className="has-text-weight-bold">
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(state.amountExpected)}
                </span>
              </h2>
              <h2>
                Total amount remitted:{" "}
                <span className="has-text-weight-bold">
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(state.amountRemitted)}
                </span>
              </h2>
              <h2>Email: {state.email}</h2>

              <h2>
                Repayment start date:{" "}
                {Array.isArray(state?.dates) && state?.dates.length > 0
                  ? new Intl.DateTimeFormat("en-NG", {
                      dateStyle: "long",
                    }).format(new Date(state.dates[0][0]))
                  : "N/A"}
              </h2>
              <h2>
                Repayment balance:{" "}
                <span className="has-text-weight-bold">
                  {new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                  }).format(state.amountExpected - state.totalRecovered)}
                </span>
              </h2>
              <h2>
                Repayment end date:{" "}
                {Array.isArray(state?.dates) && state?.dates.length > 0
                  ? new Intl.DateTimeFormat("en-NG", {
                      dateStyle: "long",
                    }).format(
                      new Date(state.dates[0][state.dates[0].length - 1])
                    )
                  : "N/A"}
              </h2>
            </div>
            <div className="right">
              <h2 className="has-text-weight-bold mr-3">Amount recovered</h2>
              <div className="select">
                <select
                  name="years"
                  id="years"
                  onChange={({ target: { value } }) => setCurrentYear(value)}
                >
                  {years.map((y, index) =>
                    index === years.length - 1 ? (
                      <option value={y} key={y} defaultValue>
                        {y}
                      </option>
                    ) : (
                      <option value={y} key={y}>
                        {y}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div>
            <div className="graph">
              <canvas ref={barRef} id="graph"></canvas>
            </div>
            <h2 className="has-text-weight-bold mt-3">Transactions</h2>
            {displayList.map((d) => (
              <div className="trans-card" key={d._id}>
                <div>
                  <p>{d.type === "deposit" ? "Repayment" : "Remittance"}</p>
                </div>
                <div>
                  <p>
                    {new Intl.DateTimeFormat("en-NG", {
                      dateStyle: "long",
                    }).format(new Date(d.createdAt))}
                  </p>
                </div>
                <div>
                  <p>{state.name}</p>
                </div>
                <div className="bold">
                  <h5 className="has-text-weight-bold">
                    {new Intl.NumberFormat("en-NG", {
                      style: "currency",
                      currency: "NGN",
                    }).format(d.amount)}
                  </h5>
                </div>
              </div>
            ))}
          </div>
          <style jsx="true">{`
            .modal-card,
            .modal-content {
              margin: 0 auto;
              max-height: calc(100vh - 40px);
              width: 100% !important;
              max-width: 960px !important;
            }
            .grid-tr {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 1rem;
            }
            @media (max-width: 800px) {
              .grid-tr {
                grid-template-columns: 1fr;
              }
            }
            .bold h5 {
              color: rgba(0, 209, 178, 1);
            }
            .trans-card {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            .select {
              margin-top: 1rem;
              margin-bottom: 2rem;
            }
            .right {
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
            }
            #graph {
              max-height: 300px;
            }
            .modal{
              z-index: 10000;
            }
            .modal-content{
              border-radius: 10px;
              padding: 20px;
            }
          `}</style>
        </div>
      </div>

      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={() => setViewModal(false)}
      ></button>
    </div>
  );
}
