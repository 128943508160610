import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import fetcher from "../utils/fetcher";

export default function VerifyAdmin() {
  let navigate = useNavigate();

  useEffect(() => {
    const token = new URLSearchParams(window.location.search).get("token");
    if (!token) {
      navigate("/success", { replace: true });
    }

    const confirmAccount = async () => {
      try {
        console.log(
          await fetcher(`/api/organizations/verify?token=${token}`, {
            method: "GET",
          })
        );
      } catch (error) {
        console.warn(error);
      } finally {
        navigate("/login", { replace: true });
      }
    };
    confirmAccount();
  }, [navigate]);

  return null;
}
