import * as React from "react";
import { Routes, Route } from "react-router-dom";
import TagManager from 'react-gtm-module';
import RequireAuth from "./components/auth";
import { AuthProvider } from "./hooks/useAuth";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Login from "./pages/login";
import VerifyAdmin from "./pages/verify";
import { TourProvider } from "@reactour/tour";
import Confirm from "./pages/confirm";
import PasswordReset from "./pages/passwordReset";
import SubmitToken from "./pages/submitToken";

const steps = [
  {
    selector: `[name="resume"]`,
    content:
      "You can upload all your debtors in bulk via an excel spreadsheet or CSV file",
  },
];

const tagManagerArgs = {
  gtmId: 'GTM-NLNTQW6'
}

TagManager.initialize(tagManagerArgs);

export default function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <TourProvider steps={steps}>
      <AuthProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/refreshToken" element={<SubmitToken />} />
          <Route path="/reset" element={<PasswordReset />} />
          <Route path="/verifyAdmin" element={<VerifyAdmin />} />
          <Route path="/verifyUser" element={<Confirm />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard/*"
            element={
              <RequireAuth>
                <Dashboard />
              </RequireAuth>
            }
          />
        </Routes>
      </AuthProvider>
    </TourProvider>
  );
}
