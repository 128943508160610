import { NavLink } from "react-router-dom";
import logo from "./../images/logo.webp";
import mailInbox from "./../images/mail-inbox.png";

export default function Confirm() {
  return (
    <div className="container">
      <div>
        <NavLink to="/">
          <img
            src="https://res.cloudinary.com/dgi7yxxpz/image/upload/v1673883520/PebbleSccore_with_Name_uzgcno.png"
            alt="Pebblescore"
            width="20%"
            className="mt-5"
          />
        </NavLink>
      </div>
      <div className="container is-flex is-justify-content-center is-align-items-center">
        <div className="columns">
          <div className="column is-7 is-offset-3">
            <div className="">
              <div className="is-justify-content-center is-align-items-center">
                <div className="mb-5 is-fullwidth">
                  <img
                    src={mailInbox}
                    alt="Pebblescore"
                    className="mt-5 ml"
                    width="20%"
                  />
                </div>
                <h2 className="title mb-6 text-primary">
                  Please verify your email
                </h2>
                <p className="subtitle">
                  We have sent you a verification email. Just click on the
                  temporary login link in your email. If you do not receive it
                  right away, please check your spam folder.
                </p>
                <p className="subtitle mb-5 mt-5">
                  Have an account already?{" "}
                  <NavLink to="/login" className="text-primary">
                    log in
                  </NavLink>{" "}
                  instead.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx={"true"}>{`
        .extend {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin-top: 11rem;
        }
        .colored {
          background: rgb(var(--pbl-purple));
          border-radius: 15px;
        }
        .text-primary {
          color: rgb(var(--pbl-purple));
        }
        .subtitle{
          line-height: 2.0rem;
        }
        .ml{
          margin-left: 150px;
        }
        .stretch {
          min-height: 100vh;
        }
        .hug {
          padding: 1rem;
        }
        .chief {
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 2rem;
          margin-top: 4rem;
        }
        .precheif {
          margin-top: 11rem;
        }
        .content h1 {
          margin-top: 3rem;
        }
        .remove-space {
          margin-bottom: -150px;
        }
      `}</style>
    </div>
  );
}
