import { Link } from "react-router-dom";
import logo from "./../images/logo.webp";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import fetcher from "../utils/fetcher";
import { useNavigate, NavLink } from "react-router-dom";

export default function PasswordReset() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = async (data) => {
    try {
      const id = toast.loading("Please wait...");
      setLoading(true);

      const { failure, message, ...rest } = await fetcher(
        `/api/organizations/update/password/reset`,
        {
          method: "PUT",
          body: JSON.stringify({ ...data }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (failure) throw new Error(message);
      setLoading(false);
      toast.dismiss();

      toast.update(id, {
        render: "Password reset token sent to your email",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate(`/refreshToken?token=${rest.id}`);
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container-fluid stretch">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="hero colored is-fullheight full-height">
        <div className="hero-head space-remove">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
              <NavLink to="/" className="navbar-item">
                  <img
                    src="https://res.cloudinary.com/dgi7yxxpz/image/upload/v1673884574/PebbleSccore_with_WhiteTXTName_ugq2l6.png"
                    alt="Logo"
                    width={'150%'}
                  />
                </NavLink>
                <span className="navbar-burger" data-target="navbarMenuHeroA">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenuHeroA" className="navbar-menu">
                <div className="navbar-end">
                  <a className="navbar-item">Home</a>
                  {/* <a className="navbar-item">What's New?</a> */}
                  <span className="navbar-item ">
                    <NavLink to="/" className="button bg-gold is-medium">
                      <span>Sign up</span>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body">
          <div className="container">
            <div className="columns  is-8-desktop is-align-items-center">
              <div className="column  is-half-tablet is-half-desktop is-hidden-mobile">
                <div className="hug is-max-desktop">
                  {/* <h3 className="subtitle is-size-3 has-text-light precheif">
                    Cobble for lenders
                  </h3> */}
                  <h1 className="chief has-text-white">Forgot Password?</h1>
                  <h4 className="is-size-4 has-text-light">
                    Enter email address to reset your password.
                  </h4>
                </div>
              </div>
              <div className="column  is-half-tablet is-half-desktop">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <h1 className="title mb-5">Reset Password</h1>
                  </div>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${errors.email ? "is-danger" : ""}`}
                        type="email"
                        placeholder="Email"
                        {...register("email", {
                          pattern: /\S+@\S+\.\S+/i,
                        })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                      {errors.email && (
                        <span className="icon is-small is-right">
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>
                      )}
                    </div>
                    {errors.email && (
                      <p className="help is-danger">
                        Please provide valid email
                      </p>
                    )}
                  </div>
                  <div className="field mt-5 mb-4">
                    <p className="control">
                      <button
                        className={`button button-purple has-text-white is-medium ${
                          loading ? "is-loading " : ""
                        }`}
                      >
                        Reset password
                      </button>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx={"true"}>{`
        .extend {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: center;
          margin-top: 11rem;
        }
        .bg-gold {
          background: rgb(var(--pbl-gold));
          border-color: rgb(var(--pbl-gold));
          height: 100%;
          overflow-y: hidden;
        }
        .button-purple {
          background: rgb(var(--pbl-purple));
          border-color: rgb(var(--pbl-purple));
          color: rgb(var(--pbl-white));
        }
        .form {
          background-color: rgb(var(--pbl-white));
          padding: 1.5rem 2.5rem;
          border-radius: 10px;
        }
        .navbar-item {
          color: #ffffff;
        }
        .colored {
          background: rgb(var(--pbl-purple));
        }
        .text-gold {
          color: rgb(var(--pbl-gold));
        }
        .colored {
          background: rgb(var(--pbl-purple));
        }
        .stretch {
          min-height: 100vh;
        }
        .hug {
          padding: 1rem;
        }
        .chief {
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
          margin-top: 4rem;
        }
        .precheif {
          margin-top: 11rem;
        }
        .content h1 {
          margin-top: 3rem;
        }
      `}</style>
    </div>
  );
}
