import { Link, NavLink, useNavigate } from "react-router-dom";
import logo from "./../images/logo.webp";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import AuthConsumer from "../hooks/useAuth";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import usePasswordToggle from "../hooks/usePasswordToggle";

export default function Home() {
  const [loading, setLoading] = useState(false);
  const [visiblePassword, visibleConfirm, iconPassword, iconConfirm] =
    usePasswordToggle();

  const validationSchema = Yup.object().shape({
    businessName: Yup.string().required("Please provide name of organization"),
    email: Yup.string()
      .required("Please provide valid email")
      .email("Email is invalid"),
    password: Yup.string()
      .required("Please provide a valid password")
      .min(6, "Password must be at least 6 characters")
      .max(40, "Password must not exceed 40 characters"),
    cpassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Confirm Password does not match"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: "",
      businessName: "",
    },
    mode: "onTouched",
    resolver: yupResolver(validationSchema),
  });

  const { signup } = AuthConsumer();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const id = toast.loading("Please wait...");
      setLoading(true);
      localStorage.removeItem("cbl-isold");
      await signup({ ...data });
      setLoading(false);
      toast.dismiss();

      toast.update(id, {
        render: "Successfully signed up please verify your account",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate("/verifyUser");
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="container-fluid stretch">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <section className="hero colored is-fullheight full-height">
        <div className="hero-head space-remove">
          <nav className="navbar">
            <div className="container">
              <div className="navbar-brand">
                <NavLink to="" className="navbar-item">
                  <img
                    src="https://res.cloudinary.com/dgi7yxxpz/image/upload/v1673884574/PebbleSccore_with_WhiteTXTName_ugq2l6.png"
                    alt="Logo"
                    width="150%"
                  />
                </NavLink>
                <span className="navbar-burger" data-target="navbarMenuHeroA">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </div>
              <div id="navbarMenuHeroA" className="navbar-menu">
                <div className="navbar-end">
                  <NavLink to="/" className="navbar-item">
                    Home
                  </NavLink>
                  {/* <NavLink to="/" className="navbar-item">
                    What's New?
                  </NavLink> */}
                  <span className="navbar-item ">
                    <NavLink to="/login" className="button bg-gold is-medium">
                      <span>Login</span>
                    </NavLink>
                  </span>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div className="hero-body">
          <div className="container">
            <div className="columns  is-8-desktop">
              <div className="column  is-half-tablet is-half-desktop is-hidden-mobile">
                <div className="hug is-max-desktop">
                  <h1 className="chief has-text-white">
                    Welcome to Pebblescore
                  </h1>
                  <h4 className="is-size-4 has-text-light">
                    Redefining debt recovery for the African lender.
                  </h4>
                </div>
              </div>
              <div className="column  is-half-tablet is-half-desktop">
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                  <div className="">
                    <h1 className="title is-size-2 mb">Create an account</h1>
                  </div>
                  <div className="field">
                    <label className="label">Name of Organization</label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${
                          errors.businessName ? "is-danger" : ""
                        }`}
                        type="text"
                        placeholder="Name of Business"
                        {...register("businessName", {
                          required: true,
                          min: 1,
                        })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-user"></i>
                      </span>
                      {errors.businessName && (
                        <span className="icon is-small is-right">
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>
                      )}
                    </div>
                    {errors.businessName && (
                      <p className="help is-danger">
                        Please provide name of organization
                      </p>
                    )}
                  </div>
                  <div className="field">
                    <label className="label">Email</label>
                    <div className="control has-icons-left has-icons-right">
                      <input
                        className={`input ${errors.email ? "is-danger" : ""}`}
                        type="email"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          pattern: /\S+@\S+\.\S+/i,
                        })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope"></i>
                      </span>
                      {errors.email && (
                        <span className="icon is-small is-right">
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>
                      )}
                    </div>
                    {errors.email && (
                      <p className="help is-danger"> {errors.email?.message}</p>
                    )}
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="password">
                      Password
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className={`input input-size ${
                          errors.password ? "is-danger" : ""
                        }`}
                        type={visiblePassword === true ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        id="password"
                        {...register("password", { required: true })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>

                      <span className="password-toggle-icon">
                        {iconPassword}
                      </span>
                      {/* {errors.password && (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              )} */}
                    </div>
                    {errors.password?.type === "required" && (
                      <p className="help is-danger">
                        {errors.password?.message}
                      </p>
                    )}
                  </div>
                  <div className="field">
                    <label className="label" htmlFor="password">
                      Confirm password
                    </label>
                    <div className="control has-icons-left">
                      <input
                        className={`input input-size ${
                          errors.cpassword ? "is-danger" : ""
                        }`}
                        type={visibleConfirm ? "text" : "password"}
                        id="cpassword"
                        placeholder="Password"
                        name="cpassword"
                        {...register("cpassword", { required: true })}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock"></i>
                      </span>
                      <span className="password-toggle-icon">
                        {iconConfirm}
                      </span>
                      {/* {errors.cpassword && (
                <span className="icon is-small is-right">
                  <i className="fas fa-exclamation-triangle"></i>
                </span>
              )} */}
                    </div>
                    {/* {errors.cpassword?.type === "required" && ( */}
                    <p className="help is-danger">
                      {errors.cpassword?.message}
                    </p>
                    {/* )} */}
                  </div>
                  <div className="field">
                    <p className="control">
                      <button
                        className={`button button-purple has-text-white is-medium mt ${
                          loading ? "is-loading " : ""
                        }`}
                      >
                        Sign up
                      </button>
                    </p>
                  </div>
                  <div className="is-size-6 mb-2 mt-5">
                    Already have an account?{" "}
                    <Link to="/login" className="text-gold">
                      Log in
                    </Link>{" "}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <style jsx={"true"}>{`
        .colored {
          background: rgb(var(--pbl-purple));
        }
        .text-gold {
          color: rgb(var(--pbl-gold));
        }
        .bg-gold {
          background: rgb(var(--pbl-gold));
          border-color: rgb(var(--pbl-gold));
          height: 100%;
          overflow-y: hidden;
        }
        .button-purple {
          background: rgb(var(--pbl-purple));
          border-color: rgb(var(--pbl-purple));
          color: rgb(var(--pbl-white));
        }
        .button-purple:hover {
          background: rgb(var(--pbl-gold));
          border-color: rgb(var(--pbl-gold));
          color: rgb(var(--pbl-white));
        
        }   
       
        .form {
          background-color: rgb(var(--pbl-white));
          padding: 1.5rem 2.5rem;
          border-radius: 10px;
        }
        .space-remove {
          margin-bottom: -20px;
        }
        .fullwidth {
          min-width: 100vw;
        }
        .mb {
          margin-bottom: 15px;
        }
        .mt{
          margin-top: 12px;
        }
        .navbar-item {
          color: #ffffff;
        }
        .navbar-item:hover {
          color: rgb(var(--pbl-gold))
          background-color: none;
        }
        .is-active{
          color: rgb(var(--pbl-gold));
        }
        .stretch {
          min-height: 100vh;
        }
        .hug {
          padding: 1rem;
        }
        .input-size {
          height: 38px;
        }
        .chief {
          font-size: 4rem;
          font-weight: bold;
          margin-bottom: 2rem;
          margin-top: 4rem;
        }
        .precheif {
          margin-top: 11rem;
        }
        .content h1 {
          margin-top: 3rem;
        }
        .password-toggle-icon {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 1000;
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}
