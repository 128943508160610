import { useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthConsumer from "../hooks/useAuth";

export default function ImageSettings() {
  const formRef = useRef();
  const { token, user } = AuthConsumer();

  return (
    <div className="container ">
      <form ref={formRef}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className="org-avatar">
          <div className="org-box">
            <input
              className="org-avatar-file"
              type="file"
              accept=".png, .jpeg, .jpg"
              onChange={async (e) => {
                if (e.target.files[0]?.name) {
                  console.log(e.target.files[0]);
                  try {
                    toast.dismiss();
                    if (e.target.files[0]?.size > 1048576 * 1)
                      throw new Error("File size cannot exceed 1mb");

                    toast.loading("Please wait...");

                    const formData = new FormData();
                    formData.append("image", e.target.files[0]);

                    const res = await fetch(`/api/organizations/upload/logo`, {
                      method: "PUT",
                      headers: {
                        authorization: `Bearer ${token}`,
                        Accept: "application/json",
                      },
                      body: formData,
                    });
                    if (!res.ok || !res.status.toString().startsWith("2")) {
                      throw await res.json();
                    }
                    await res.json();

                    window.location.reload();
                  } catch (err) {
                    toast.dismiss();
                    toast.error(err.message, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                }
              }}
            />
            <div className="veil">
              <div className="dark-wrapper">
                <i className="fa-solid fa-camera"></i>
              </div>
            </div>
            {user.image && (
              <img src={user.image} alt="avatar" className="user-avatar" />
            )}
          </div>
        </div>
        <small style={{ paddingLeft: "1.2rem", marginBottom: "3rem" }}>
          Change photo
        </small>
      </form>
      <style jsx="true">{`
        .org-avatar {
          display: flex;
          padding-left: 1rem;
          margin-top: 2rem;
          margin-bottom: 0.5rem;
        }
        .org-box {
          cursor: pointer;
          background-color: #eee;
          position: relative;
          height: 100px;
          width: 100px;
          border-radius: 50%;
          overflow: hidden;
        }
        .org-box img {
          object-fit: cover;
          width: 100px;
          height: 100px;
        }
        .org-avatar-file {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100px;
          height: 100px;
          opacity: 0;
          cursor: pointer;
          z-index: 2;
        }
        .dark-wrapper {
          background-color: rgba(0, 0, 0, 0.7);
          padding: 1rem;
          border-radius: 50%;
          font-size: 20px;
          line-height: 20px;
        }
        .veil {
          justify-content: center;
          align-items: center;
          display: flex;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100px;
          height: 100px;
          text-align: center;

          cursor: pointer;
          z-index: 1;
          background-color: transparent;
          color: #fff;
        }
      `}</style>
    </div>
  );
}
