import BankSettings from "./bankSettings";
import PasswordSettings from "./passwordSettings";
import PersonalSettings from "./personalSettings";
import { Helmet } from "react-helmet";

export default function SettingsContainer() {
  return (
    <div className="settingsContainer mt px-5">
      <Helmet>
        <title>Debt Recovery Portal | Settings</title>
      </Helmet>
      <PersonalSettings />
      <BankSettings />
      <PasswordSettings />
      <style jsx="true">
        {`
          .mt{
            margin-top: 100px;
          }
        `}
      </style>
    </div>
  );
}
