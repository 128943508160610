import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthConsumer from "../hooks/useAuth";
import fetcher from "../utils/fetcher";
import ImageSettings from "./imageSettings";

export default function PersonalSettings() {
  const [loading, setLoading] = useState(false);
  const { token, user } = AuthConsumer();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      email: user.email,
      businessName: user.businessName,
      phoneNumber: user?.phoneNumber || "",
    },
  });
  useEffect(() => {
    reset({
      email: user.email,
      businessName: user.businessName,
      phoneNumber: user?.phoneNumber || "",
    });
  }, [user.email, user.businessName, user.phoneNumber, reset]);
  const onSubmit = async (data) => {
    try {
      const id = toast.loading("Please wait...");
      setLoading(true);

      const { failure, message } = await fetcher(`/api/organizations/update`, {
        method: "PUT",
        body: JSON.stringify({ ...data }),
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (failure) throw new Error(message);
      setLoading(false);
      toast.dismiss();

      toast.update(id, {
        render: "Successfully updated your profile",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload();
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container mt-2 mb-4">
      <div className="box">
        <ImageSettings />
        <h1 className="title is-2 text-primary">Organizational Details</h1>

        <form onSubmit={handleSubmit(onSubmit)}>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />

          <div className="pack">
            <div className="half-pack">
              <div className="field">
                <label className="label">Name of Organization</label>

                <div className="control has-icons-left has-icons-right">
                  <input
                    disabled
                    readOnly
                    className={`input ${
                      errors.businessName ? "is-danger" : ""
                    }`}
                    type="text"
                    defaultValue={"stuff"}
                    placeholder="Name of Business"
                    {...register("businessName", { min: 1 })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user"></i>
                  </span>
                  {errors.businessName && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </div>
                {errors.businessName && (
                  <p className="help is-danger">
                    Please provide name of organization
                  </p>
                )}
              </div>
              <label className="label">Phone Number</label>

              <div className="field">
                <div className="control has-icons-left has-icons-right">
                  <input
                    maxLength={11}
                    minLength={11}
                    className={`input ${errors.phoneNumber ? "is-danger" : ""}`}
                    type="tel"
                    placeholder="Phone number"
                    {...register("phoneNumber", {
                      pattern: {
                        value: new RegExp(/^\d{11}$/),
                        message: "Phone number must be at least 11 digits",
                      },
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-phone"></i>
                  </span>

                  {errors.phoneNumber && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </div>
                {errors.phoneNumber && (
                  <p className="help is-danger">
                    Please provide valid phone number
                  </p>
                )}
              </div>
            </div>

            <div className="half-pack">
              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    disabled
                    readOnly
                    className={`input ${errors.email ? "is-danger" : ""}`}
                    type="email"
                    placeholder="Email"
                    {...register("email", {
                      pattern: /\S+@\S+\.\S+/i,
                    })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope"></i>
                  </span>
                  {errors.email && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </div>
                {errors.email && (
                  <p className="help is-danger">Please provide valid email</p>
                )}
              </div>
            </div>
          </div>
          <div className="field mt-5 mb-4">
            <p className="control">
              <button
                className={`button button-gold is-medium ${
                  loading ? "is-loading " : ""
                }`}
              >
                Save changes
              </button>
            </p>
          </div>
        </form>
      </div>

      <style jsx="true">
        {`
          .pack {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            grid-gap: 3rem;
            justify-content: space-between;
          }
          .text-primary {
            color: rgb(var(--pbl-purple));
          }
          .colored {
            background: rgb(var(--pbl-purple));
          }
          .text-gold {
            color: rgb(var(--pbl-gold));
          }
          .bg-gold {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            height: 100%;
            overflow-y: hidden;
          }
          .button-purple {
            background: rgb(var(--pbl-purple));
            border-color: rgb(var(--pbl-purple));
            color: rgb(var(--pbl-white));
          }
          .button-purple:hover {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            color: var(--pbl-black);
          }
        `}
      </style>
    </div>
  );
}
