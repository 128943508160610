import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import AuthConsumer from "../hooks/useAuth";

const Sidebar = () => {
  const { logout } = AuthConsumer();
  const [collapse, setCollapse] = useState(false);
  return (
    <div className="fixed bg-purple">
      <div className="navbar-brand">
        <Link to="/dashboard" className="navbar-item">
          <h1>
            <img
              className="logo"
              alt="Pebblescore logo"
              src={
                "https://res.cloudinary.com/dgi7yxxpz/image/upload/v1673884574/PebbleSccore_with_WhiteTXTName_ugq2l6.png"
              }
            />
          </h1>
        </Link>

        <a
        //   className={`navbar-burger burger ${isActive ? "is-active" : ""}`}
        //   onClick={() => setIsActive((s) => !s)}
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
      <aside class="menu mt-5 ">
        <ul class="menu-list">
          <li>
            <NavLink
              to="/dashboard/"
              className={({ isActive }) =>
                isActive
                  ? "is-active is-uppercase mb-5 md:hover:text-black-500"
                  : "is-uppercase mb-5"
              }
            >
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              onClick={() => setCollapse(!collapse)}
              to="#"
              className={({ isActive }) =>
                isActive
                  ? "link mb-5 is-uppercase"
                  : "mb-5 is-uppercase"
              }
            >
              Debtors <span className="arrow">&#8594;</span>
            </NavLink>
            <ul>
              {collapse === true && (
                <div>
                  <li>
                    <NavLink to="/dashboard/debtors" className={({ isActive }) =>
                isActive
                  ? "is-active link mb-5 is-uppercase"
                  : "mb-5 is-uppercase"
              }>
                      Debtors list
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/upload"
                      className={({ isActive }) =>
                        isActive ? "is-active mb-5 as-text-white is-uppercase" : "mb-5 is-uppercase"
                      }
                    >
                      Upload debtors
                    </NavLink>
                  </li>
                </div>
              )}
            </ul>
          </li>

          <li>
            <NavLink
              to="/dashboard/transactions"
              className={({ isActive }) =>
                isActive ? "is-active is-uppercase mb-5" : "is-uppercase mb-5"
              }
            >
              Transactions
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/dashboard/settings"
              className={({ isActive }) =>
                isActive ? "is-active is-uppercase mb-5" : "is-uppercase mb-5"
              }
            >
              Settings
            </NavLink>
          </li>
          <li>
            <NavLink
              to="#"
              onClick={() => {
                logout();
              }}
              className={"is-uppercase mb-5"}
            >
              Logout
            </NavLink>
          </li>
        </ul>
      </aside>
      <style jsx="true">{`
      .fixed{
        position: fixed;
        z-index: 0;
        width: 20%;
      }
      .bg-purple {
        background: rgb(var(--pbl-purple));
        border-color: rgb(var(--pbl-purple));
        height: 100%;
        overflow-y: hidden;
        top:0;
        left:0;
      }
      `}</style>
    </div>
  );
};

export default Sidebar;
