import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import AuthConsumer from "../hooks/useAuth";
import fetcher from "../utils/fetcher";

export default function BankSettings() {
  const { token, user } = AuthConsumer();
  const [loading, setLoading] = useState(false);
  const [loadingBanks, setLoadingBanks] = useState(true);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [banks, setBanks] = useState([]);
  useEffect(() => {
    fetcher(`/api/organizations/account/banks`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      setBanks([...res.banks]);
      setLoadingBanks(false);
    });
    return () => {
      setBanks([]);
    };
  }, [fetcher, setBanks, setLoading, token]);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      acNumber: user.acNumber || "",
      bank: user.bank || "",
      acName: user.acName || "",
    },
  });
  useEffect(() => {
    reset({
      acNumber: user.acNumber || "",
      bank: user.bank || "",
      acName: user.acName || "",
    });
  }, [user.acNumber, user.bank, user.acName, reset]);
  useEffect(() => {
    const subscription = watch(async (value, { name }) => {
      try {
        const { acNumber, bank } = value;

        if (
          acNumber.trim().length === 10 &&
          bank &&
          name !== "acName" &&
          user.businessName
        ) {
          if (
            user?.bank?.length > 0 &&
            user?.acNumber?.length > 0 &&
            user.bank == bank &&
            user.acNumber == acNumber
          ) {
            return;
          }
          const id = toast.loading("Please wait...");
          setLoadingAccount(true);

          const res = await fetcher(`/api/organizations/account/verify`, {
            method: "POST",
            body: JSON.stringify({ ...value }),
            headers: {
              authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          });
          if (res.failure) throw new Error(res.message);
          const { account_name } = res;
          setValue("acName", account_name);
          setLoadingAccount(false);

          toast.update(id, {
            render: "Success",
            type: "success",
            isLoading: false,
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      } catch (err) {
        setLoadingAccount(false);
        toast.dismiss();
        toast.error(err, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [
    watch,
    token,
    fetcher,
    setValue,
    user.businessName,
    user.bank,
    user.acNumber,
    user.acName,
  ]);
  const onSubmit = async (data) => {
    try {
      toast.dismiss();
      const id = toast.loading("Please wait...");
      setLoading(true);
      const values = getValues();
      const { failure, message } = await fetcher(`/api/organizations/update`, {
        method: "PUT",
        body: JSON.stringify({
          ...values,
          bankName: banks.find((b) => b.code === values.bank)?.name,
        }),
        headers: {
          authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      if (failure) throw new Error(message);
      setLoading(false);
      toast.dismiss();

      toast.update(id, {
        render: "Successfully updated your profile",
        type: "success",
        isLoading: false,
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      window.location.reload();
    } catch (err) {
      setLoading(false);
      toast.dismiss();
      toast.error(err, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <div className="container mt-2 mb-4">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <div className="box">
          <h1 className="title is-2 text-primary" id="bank">Bank Details</h1>
          <div className="pack">
            <div className="half-pack">
              <div className="field">
                <label className="label">Bank name</label>
                <div className="control has-icons-left has-icons-right">
                  <div
                    className={`select ${errors.bank ? "is-danger" : ""} ${
                      loadingBanks ? "is-loading" : ""
                    }`}
                  >
                    <select
                      style={{ width: "100%" }}
                      {...register("bank", { required: true })}
                      disabled={user.bank ? true : false}
                    >
                      {!user?.bank && <option>Select bank</option>}
                      {[...banks]
                        .sort((a, b) => {
                          if (a.code === user.bank) {
                            return -1;
                          }
                          if (b.code === user.bank) {
                            return 1;
                          }
                          return 0;
                        })
                        .map((b) => (
                          <option value={b.code} key={b.code}>
                            {b.name}
                          </option>
                        ))}
                    </select>
                  </div>

                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-building-columns"></i>
                  </span>
                  {errors.bank && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </div>
                {errors.bank && (
                  <p className="help is-danger">Please provide bank name</p>
                )}
              </div>
              <div className="field">
                <label className="label">Account name</label>
                <p className={`control has-icons-left has-icons-right`}>
                  <input
                    disabled
                    readOnly
                    className={`input ${errors.acName ? "is-danger" : ""}`}
                    type="text"
                    placeholder="Account name"
                    {...register("acName", { required: true })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-file-signature"></i>
                  </span>
                  {errors.acName && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </p>
                {errors.acName && (
                  <p className="help is-danger">
                    Please confirm your account name
                  </p>
                )}
              </div>
            </div>

            <div className="half-pack">
              <div className="field">
                <label className="label">Account number</label>
                <p className="control has-icons-left has-icons-right">
                  <input
                    disabled={Boolean(user.bank) || loadingAccount}
                    className={`input ${errors.acNumber ? "is-danger" : ""}`}
                    type="text"
                    maxLength={10}
                    placeholder="Account number"
                    {...register("acNumber", { required: true })}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-pen"></i>
                  </span>
                  {errors.acNumber && (
                    <span className="icon is-small is-right">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  )}
                </p>
                {errors.acNumber && (
                  <p className="help is-danger">
                    Please provide valid account number
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="field mt-5 mb-4">
            <p className="control">
              <button
                disabled={
                  Boolean(user.bank) ||
                  loadingBanks ||
                  loadingAccount ||
                  loading
                }
                className={`button button-gold is-medium ${
                  loading ? "is-loading " : ""
                }`}
              >
                Save changes
              </button>
            </p>
          </div>
        </div>
      </form>
      <style jsx="true">
        {`
          .select {
            width: 100%;
          }
          .pack {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
            grid-gap: 3rem;
            justify-content: space-between;
          }
          .button-gold {
            background: rgb(var(--pbl-gold));
            border-color: rgb(var(--pbl-gold));
            color: rgb(var(--pbl-black));
          }
        `}
      </style>
    </div>
  );
}
